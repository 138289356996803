import React from 'react';
import PropTypes from 'prop-types';
import Icon from '../Icon';

const BackButton = ({
  text,
  onClick,
  ariaLabel = 'Go back to previous page'
}) => (
  <button
    type="button"
    className="btn btn-outline flex items-center content-center pl-3.5 w-full sm:w-auto max-w-full"
    onClick={onClick}
    aria-label={ariaLabel}
  >
    <span
      className="bg-gray-100 rounded-full flex flex-shrink-0 flex-grow-0 items-center justify-center w-10 h-10 mr-4"
      aria-hidden="true"
    >
      <Icon name="chevronLeft" className="text-blue w-5 h-5" />
    </span>
    <span className="truncate">{text}</span>
  </button>
);

BackButton.propTypes = {
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  ariaLabel: PropTypes.string
};

export default BackButton;
