import React, { useCallback, useEffect } from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import BackButton from '../../components/BackButton/BackButton';
import Button from '../../components/Button/Button';
import PropTypes from 'prop-types';
import { regex } from '../../utils';
import LocationInput from '../../components/LocationInput/LocationInput';
import Input from '../../components/Input/Input';

const inputConfigType = PropTypes.shape({
  disabled: PropTypes.bool,
  hidden: PropTypes.bool
});

const GivenNameField = ({ config }) => {
  if (config?.hidden) {
    return null;
  }

  const {
    register,
    watch,
    formState: { errors }
  } = useFormContext();

  document.title = 'Basic Information - Dispatch';

  return (
    <span>
      <Input
        id="given_name"
        name="given_name"
        inputRef={register({
          required: 'First name is required'
        })}
        label="First Name"
        value={watch('given_name')}
        disabled={config?.disabled}
        hasErrors={errors.given_name ? true : false}
        customErrorMessage={errors?.given_name?.message}
        visuallyRequired
      />
    </span>
  );
};

GivenNameField.propTypes = {
  config: inputConfigType
};

const FamilyNameField = ({ config }) => {
  if (config?.hidden) {
    return null;
  }

  const {
    register,
    watch,
    formState: { errors }
  } = useFormContext();
  return (
    <span>
      <Input
        id="family_name"
        name="family_name"
        inputRef={register({
          required: 'Last name is required'
        })}
        label="Last Name"
        value={watch('family_name')}
        disabled={config?.disabled}
        hasErrors={errors.family_name ? true : false}
        customErrorMessage={errors?.family_name?.message}
        visuallyRequired
      />
    </span>
  );
};

FamilyNameField.propTypes = {
  config: inputConfigType
};

const OrganizationNameField = ({ config }) => {
  if (config?.hidden) {
    return null;
  }

  const {
    register,
    watch,
    formState: { errors }
  } = useFormContext();
  return (
    <span>
      <Input
        id="organization_name"
        className="form-control"
        name="organization_name"
        inputRef={register({
          required: 'Organization name is required'
        })}
        label="Organization Name"
        value={watch('organization_name')}
        disabled={config?.disabled}
        hasErrors={errors.organization_name ? true : false}
        customErrorMessage={errors?.organization_name?.message}
        visuallyRequired
      />
    </span>
  );
};

OrganizationNameField.propTypes = {
  config: inputConfigType
};

const PhoneField = ({ config }) => {
  if (config?.hidden) {
    return null;
  }

  const {
    formState: { errors },
    control
  } = useFormContext();
  return (
    <span>
      <Controller
        name="phone_number"
        control={control}
        rules={{
          required: 'Phone number is required',
          pattern: {
            value: regex.phone,
            message: 'Invalid phone number'
          }
        }}
        defaultValue=""
        render={({ onChange, name, value }) => (
          <Input
            id="phone_number"
            name={name}
            mask="899-999-9999"
            type="tel"
            label="Phone Number"
            hasErrors={errors.phone_number ? true : false}
            customErrorMessage={errors?.phone_number?.message}
            value={value}
            disabled={config?.disabled}
            retainInvaildOnChange
            onChange={e => onChange(e.target.value)}
            visuallyRequired
          />
        )}
      />
    </span>
  );
};

PhoneField.propTypes = {
  config: inputConfigType
};

const AddressField = ({ config }) => {
  if (config?.hidden) {
    return null;
  }

  const {
    watch,
    register,
    formState: { errors },
    getValues,
    setValue,
    trigger
  } = useFormContext();
  const validateAddress = () => {
    const values = getValues();
    const address = values.address;

    if (!address) {
      return 'Address is required';
    }

    // Check if address has at least one field filled
    const hasAddress = Object.keys(address).some(key => values.address[key]);
    return hasAddress || 'Address is required';
  };

  useEffect(() => {
    register('address', { validate: validateAddress });
  }, []);

  const handleAddressChange = useCallback(addressData => {
    if (addressData) {
      // Register and set the value for each address field
      for (const field of Object.keys(addressData)) {
        register(`address.${field}`);
        setValue(`address.${field}`, addressData[field]);
      }
    } else {
      // Clear the address fields
      for (const field of Object.keys(getValues().address)) {
        setValue(`address.${field}`, '');
      }
    }
    trigger('address');
  });

  return (
    <span className="sm:col-span-2">
      <LocationInput
        label="Organization Address"
        placeholder="Type an address or business name"
        inputName="address"
        namePrefix="address"
        idPrefix="address"
        require="true"
        disabled={config?.disabled}
        // skipcq: JS-0400
        showRequiredIndicator
        value={watch('address')?.description}
        autocompleteOptions={{
          saved_locations: [],
          recent_locations: []
        }}
        handleLocationChange={handleAddressChange}
        hasErrors={errors.address ? true : false}
        customErrorMessage={errors?.address?.message}
        visuallyRequired
      />
    </span>
  );
};

AddressField.propTypes = {
  config: inputConfigType
};

const IntentField = ({ intentValues, config }) => {
  if (config?.hidden) {
    return null;
  }

  const { register } = useFormContext();
  return (
    <span className="sm:col-span-2 w-6/12">
      <label htmlFor="intent">Expected number of deliveries per week</label>
      <select
        id="intent"
        name="intent"
        className="form-control "
        ref={register()}
      >
        <option value="" />
        {intentValues.map(intent => (
          <option key={intent} value={intent}>
            {intent}
          </option>
        ))}
      </select>
    </span>
  );
};

IntentField.propTypes = {
  intentValues: PropTypes.arrayOf(PropTypes.string),
  config: inputConfigType
};

const ReferredCheckField = ({ config }) => {
  if (config?.hidden) {
    return null;
  }

  const { register } = useFormContext();
  return (
    <span className="sm:col-span-2 flex items-start gap-4">
      <input id="referred" name="referred" type="checkbox" ref={register()} />
      <label htmlFor="referred">
        I was referred by an existing customer of Dispatch
      </label>
    </span>
  );
};

ReferredCheckField.propTypes = {
  config: inputConfigType
};

const ReferredInputField = ({ config }) => {
  if (config?.hidden) {
    return null;
  }

  const {
    watch,
    register,
    formState: { errors },
    setValue
  } = useFormContext();
  // Used to clear the ad_hoc_referral field when the referred checkbox is unchecked
  const referred = watch('referred');
  useEffect(() => {
    if (!referred) {
      setValue('ad_hoc_referral', '');
    }
  }, [referred, setValue]);

  return (
    <span
      className={`sm:col-span-2 -mt-4 transition-all duration-500 ease-in-out ${
        referred ? 'opacity-100 max-h-40' : 'opacity-0 max-h-0'
      } overflow-hidden`}
    >
      <Input
        id="ad_hoc_referral"
        name="ad_hoc_referral"
        placeholder="Business Name"
        inputRef={register({
          required: referred ? 'Referral company is required' : false
        })}
        value={watch('ad_hoc_referral')}
        label="Referring Company"
        hasErrors={errors.ad_hoc_referral ? true : false}
        customErrorMessage={errors?.ad_hoc_referral?.message}
        visuallyRequired
      />
    </span>
  );
};

ReferredInputField.propTypes = {
  config: inputConfigType
};

const TermsCheckField = ({ neededTerms, config }) => {
  if (config?.hidden) {
    return null;
  }

  const { register, errors } = useFormContext();
  return (
    <div className="sm:col-span-2">
      <span className="flex items-start gap-4">
        <input
          id="terms"
          name="terms"
          type="checkbox"
          ref={register({ required: 'Please accept the terms and conditions' })}
        />

        <label htmlFor="terms">
          <abbr title="This field is required.">*</abbr> I have reviewed the
          below linked documents, and agree to the terms and conditions set
          forth herein.
        </label>
      </span>

      {errors.terms && (
        <span className="text-red-500 text-sm">{errors.terms.message}</span>
      )}

      <ul className="ml-16 mb-16 list-disc">
        {neededTerms.map(term => (
          <li key={term.label}>
            <a href={term.path} target="_blank" rel="noreferrer">
              {term.label}
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
};

TermsCheckField.propTypes = {
  neededTerms: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      path: PropTypes.string
    })
  ),
  config: inputConfigType
};

const BasicInfoPage = ({
  back,
  intentValues = [],
  neededTerms = [],
  inputConfig = {},
  button
}) => {
  const { watch, reset, getValues } = useFormContext();
  const watchEmail = watch('email');

  const hideTerms = inputConfig?.terms?.hidden || false;

  const showTerms = !hideTerms && neededTerms.length > 0;
  const gridMargin = !showTerms ? 'mb-16' : '';

  // Navigate back to previous step and clear password
  const handleBackButtonClick = useCallback(() => {
    reset({ ...getValues(), password: '' });
    back();
  });

  return (
    <div className="max-w-5xl mx-auto">
      <h2 className="mb-10 mt-12">Basic information</h2>
      <BackButton onClick={handleBackButtonClick} text={watchEmail} />
      <p className="mt-10 mb-9 text-[17px]">
        Provide your details to help us offer you a more personalized and
        convenient service experience.
      </p>

      <div className={`grid grid-cols-1 sm:grid-cols-2 gap-9 ${gridMargin}`}>
        <GivenNameField config={inputConfig?.given_name} />
        <FamilyNameField config={inputConfig?.family_name} />
        <OrganizationNameField config={inputConfig?.organization_name} />
        <PhoneField config={inputConfig?.phone} />
        <AddressField config={inputConfig?.address} />
        <IntentField config={inputConfig?.intent} intentValues={intentValues} />
        <ReferredCheckField config={inputConfig?.referral} />
        <ReferredInputField config={inputConfig?.referral} />
        <TermsCheckField
          config={inputConfig?.terms}
          neededTerms={neededTerms}
        />
      </div>

      {button || (
        <Button type="submit" className="btn-lg" style="bright" size="full">
          Create Account
        </Button>
      )}
    </div>
  );
};

BasicInfoPage.propTypes = {
  back: PropTypes.func,
  intentValues: PropTypes.arrayOf(PropTypes.string),
  neededTerms: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      path: PropTypes.string
    })
  ),
  inputConfig: PropTypes.shape({
    given_name: inputConfigType,
    family_name: inputConfigType,
    phone: inputConfigType,
    organization_name: inputConfigType,
    address: inputConfigType,
    terms: inputConfigType,
    intent: inputConfigType,
    referral: inputConfigType
  }),
  button: PropTypes.node
};

export default BasicInfoPage;
